import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import constants from "../styles/constants"

function getArtPieces(props) {
  return props.data.allMarkdownRemark.edges.map(edge => ({
    slug: edge.node.fields.slug,
    title: edge.node.frontmatter.title,
    imageFixed: edge.node.frontmatter.images[0].childImageSharp.fixed,
  }))
}

const OneArtPiece = ({ title, slug, imageFixed }) => (
  <a
    href={slug}
    key={slug}
    style={{
      background: "#fff",
      boxShadow: constants.simpleShadow,
      display: "block",
      padding: "1.5rem",
      margin: "0 0 0.5rem 0.5rem",
    }}
  >
    <h4 style={{ margin: "0 0 0.5rem", fontSize: "88%" }}>{title}</h4>
    <div>{<Img fixed={imageFixed} />}</div>
  </a>
)

const CategoryPage = props => {
  const artPieceData = getArtPieces(props)
  const { title, slug } = props.pageContext
  return (
    <Layout tan activeSectionSlug={slug} allowClickAnyway={false}>
      <SEO title={title} />
      <h1>{title}</h1>

      <div style={{ display: "inline-flex", flexWrap: "wrap" }}>
        {artPieceData.map(OneArtPiece)}
      </div>
    </Layout>
  )
}
export default CategoryPage

// the "fields.slug" value is assigned dynamically in gatsby-node; it's not
// part of the schema (i.e., not manually named in each MD file)
export const pageQuery = graphql`
  query GetArtPiecesInCategory($artPieces: [String]) {
    allMarkdownRemark(filter: { fields: { slug: { in: $artPieces } } }) {
      edges {
        node {
          frontmatter {
            title
            images {
              childImageSharp {
                fixed(width: 300, height: 300, cropFocus: CENTER) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

/*
      <ul>
        {artPieces.map(pieceId => <li key={pieceId}><a href={`/${pieceId}`}>{pieceId}</a></li>)}
      </ul>
*/
